import React from "react";

import Layout from "components/Layout"
import { Container } from "components/Container";

const DonationPolicyPage = () => (
  <Layout>
    <Container>
      <div className="relative bg-white mb-10">
        <h1 className="mb-5 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Donation Policy</h1>
        <p>
          It is the goal of our campaign to provide unprecedented levels of transparency. Campaign donations will be posted to our website in real time, donors will be vetted to keep funds from developers and special interest out of the campaign and ensures the interests of the community are put first not only during the campaign but, most importantly, after.
        </p>
        <p>
          This far exceeds the Provincial regulations that require each candidate to report all contributions AFTER the election leaving the voter in the dark as to who exactly supported a candidate and we feel this is valuable information to consider BEFORE casting a vote.
        </p>
      </div>
    </Container>
  </Layout>
)

export default DonationPolicyPage
export { Head } from "components/Head"
